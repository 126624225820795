$background-color_1: #fff;
$background-color_2: rgba(10, 10, 141, 0.801);
$border-color_1: #fff;

.roundContainer {
    position: absolute;
    top: 0;
}

.round {
    position: relative;

    label {
        background-color: $background-color_1;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 5px;
        position: absolute;
        top: 5px;
        width: 20px;

        &:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 6px;
            left: 3px;
            opacity: 0;
            position: absolute;
            top: 5px;
            transform: rotate(-45deg);
            width: 12px;
        }
    }

    input[type="checkbox"] {
        visibility: hidden;

        &:checked {
            + {
                label {
                    background-color: $background-color_2;
                    border-color: $border-color_1;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}