/**===== dots5 =====*/
.dots-spinner {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    span {
        position: absolute;
        width: 10px;
        height: 10px;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        -webkit-animation: dots5 1s infinite ease-in-out;
        animation: dots5 1s infinite ease-in-out;

        &:nth-child(1) {
            left: 0px;
            -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
        }

        &:nth-child(2) {
            left: 15px;
            -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
        }
        &:nth-child(3) {
            left: 30px;
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
        }
        &:nth-child(4) {
            left: 45px;
            -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
        }
      }
}

  @keyframes dots5 {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: #d62d20;
    }
    25% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      background: #ffa700;
    }
    50% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      background: #008744;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: #0057e7;
    }
  }
  @-webkit-keyframes dots5 {
    0% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: #d62d20;
    }
    25% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      background: #ffa700;
    }
    50% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
      background: #008744;
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
      background: #0057e7;
    }
  }