#greetTextArea {
    margin: 10px 10px;
}

#greetingRemark {
    margin: 0 10px 10px 10px;
}

#addChargeDetail {
    margin: 0 10px 10px 10px;
    font-weight: bold;
}